import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ClienteUtil } from "../util/cliente.util";

@Injectable({
  providedIn: "root",
})
export class CriterioInsercaoService {
  private valoresVale = [
    {
      label: this.translateService.instant("crudAcao.selecione"),
      value: null,
    },
    {
      label: this.translateService.instant("formacao.maquinistas"),
      value: "Maquinistas",
    },
    {
      label: this.translateService.instant("formacao.operadorAutoLinha"),
      value: "Operador de Auto de Linha",
    },
    {
      label: this.translateService.instant("formacao.cco"),
      value: "CCO (Técnico Controle Integrado, Inspetor Controle Integrado, Analista Controle Integrado)",
    },
    {
      label: this.translateService.instant("formacao.operadorPoroDNeCN"),
      value: "Operadores do Porto DN e CN",
    },
    {
      label: this.translateService.instant("formacao.monotonia"),
      value: "Monotonia",
    },
    {
      label: this.translateService.instant("formacao.ma"),
      value: "MA",
    },
    {
      label: this.translateService.instant("formacao.pa"),
      value: "PA",
    },
    {
      label: this.translateService.instant("formacao.rac2Restrito"),
      value: "RAC 2 (área restrita)",
    },
    {
      label: this.translateService.instant("formacao.rac3"),
      value: "RAC 3",
    },
    {
      label: this.translateService.instant("formacao.rac5"),
      value: "RAC 5",
    },
    {
      label: this.translateService.instant("formacao.rac10"),
      value: "RAC 10",
    },
    {
      label: this.translateService.instant("formacao.naoElegivel"),
      value: "Não elegíveis",
    },
  ];

  private valoresValeNorte = [
    {
      label: this.translateService.instant("crudAcao.selecione"),
      value: null,
    },
    {
      label: this.translateService.instant("formacao.salobo"),
      value: "SALOBO",
    },
    {
      label: this.translateService.instant("formacao.oncaPuma"),
      value: "ONÇA PUMA",
    },
    {
      label: this.translateService.instant("formacao.sossego"),
      value: "SOSSEGO",
    },
    {
      label: this.translateService.instant("formacao.maranhao"),
      value: "MARANHÃO",
    },
    {
      label: this.translateService.instant("formacao.para"),
      value: "PARÁ",
    },
    {
      label: this.translateService.instant("formacao.bmsa"),
      value: "BMSA",
    },
    {
      label: this.translateService.instant("formacao.terminalCobre"),
      value: "TERMINAL DE COBRE",
    },
  ];

  constructor(private translateService: TranslateService) {}

  getValoresPreDefinidos() {
    let valores;
    if (ClienteUtil.isVale()) {
      valores = this.valoresVale;
    } else if (ClienteUtil.isBmsa()) {
      valores = this.valoresValeNorte;
    } else {
      return null;
    }
    valores.sort((a, b) => a.value && b.value && a.label.localeCompare(b.label));
    return valores;
  }
}
